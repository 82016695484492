import { ArtifactSeoTopicTabListSection } from 'domains/ArtifactSeo/ArtifactSeoTopicTabListSection'
import Image from 'domains/Sanity/Image'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import { ErrorMessage, Loading } from 'components'

import { Homepage, HomepageContent, useMarketingHomepageQuery } from 'gql'

import AiSection from './AiSection'
import ArtifactAndGuideSection from './ArtifactAndGuideSection'
import JoinCtaSection from './JoinCtaSection'
import LoggedOutHomepageHero from './LoggedOutHomepageHero'
import LogoSection from './LogoSection'
import MembershipSection from './MembershipSection'
import TeamSection from './TeamSection'
import TestimonialSection from './TestimonialSection'
import facetBg from './facet-visual.svg'

const LoggedOutHomepage = () => {
  const { data, loading } = useMarketingHomepageQuery()
  const page = (data?.marketingHomepage || {}) as Homepage
  const { content } = page

  if (!loading && !content) {
    return <ErrorMessage />
  }

  return (
    <MarketingLayout page={page} transparentNav>
      {loading ? (
        <Loading className="mt-64" />
      ) : (
        <LoggedOutHomepageContent content={content} />
      )}
    </MarketingLayout>
  )
}

export const LoggedOutHomepageContent = ({ content }: { content: HomepageContent }) => {
  return (
    <>
      <LoggedOutHomepageHero content={content} />
      <LogoSection logos={content?.logos} />
      <section className="px-4 md:px-[4vw] w-full relative overflow-hidden">
        <div className="mx-auto max-w-[1280px]">
          <Image
            src={facetBg}
            alt=""
            className="object-cover -z-1 mt-[100px] sm:mt-0"
            fill
          />
          <MembershipSection content={content} />
        </div>
      </section>
      <ArtifactAndGuideSection content={content} />
      <TestimonialSection content={content} />
      <AiSection content={content} />
      <TeamSection content={content} />
      <JoinCtaSection content={content} />

      <div className="px-4 md:px-[4vw] py-[50px] lg:py-[100px]">
        <div className="mx-auto max-w-[1280px]">
          <ArtifactSeoTopicTabListSection />
        </div>
      </div>
    </>
  )
}

export default LoggedOutHomepage
